<template>
  <v-card>
      <v-card-title v-if="!edit" class="text-h4 font-weight-bold">สร้างประกาศ<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-title v-else class="text-h4 font-weight-bold">แก้ไขประกาศ<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="text-left">
         <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="ma-0 pa-0">
              <v-col cols="12">
                  <TextField :title="'หัวข้อ'" :required="true" v-model="value.title" :rules="titleRules"/>
              </v-col>
               <v-col cols="12">
                 <v-row>
                   <v-col cols="auto">
              <!-- <SwitchButton :title="'การมองเห็น'"  v-model="value.isPin"/> -->
               <label class="text-title-4 natural--text text--darken-4">การมองเห็น </label>
              <v-switch inset v-model="value.isPin"></v-switch>
                   </v-col>
              <v-col class="mt-4" align-self="center">
    {{(value.isPin)? 'มองเห็นตลอดเวลา': 'เฉพาะผู้ป่วยปัจจุบันในระบบ'}}
</v-col>
                 </v-row>
               </v-col>
               <v-col cols="12">
                  <TextArea :title="'เนื้อหา'" :required="true" v-model="value.body" :rules="bodyRules"/>
              </v-col>
                <v-col cols="12"  >
                <Select :title="'ประเภท'" :rules="newsTypeRules" :placeholder="'ประเภท'" :items="newsTypeList" v-model="value.newsType" item-text="name" item-value="id"/>
              </v-col>
            <v-col cols="12"  >
                <Select :title="'ส่งถึง'" :rules="receiverRules" :placeholder="'ส่งถึง'" :items="receiverList" v-model="value.newsReceiver" item-text="name" item-value="id"/>
              </v-col>
                 <v-col cols="12" >
                <Select :disabled="value.newsReceiver !== 'group_patient'" :rules="(value.newsReceiver === 'group_patient')? groupRules : []" :title="'กลุ่ม'" :placeholder="'ส่งถึง'" :items="groupList" v-model="value.groupId" item-text="groupName" item-value="id"/>
              </v-col>
               <v-col cols="12">
                  <v-img :src="imgUrl" width="100%"/>
              </v-col>
               <v-col cols="12">
               <v-file-input
    :rules="fileRules"
    accept="image/png, image/jpeg, image/bmp"
    placeholder="กรุณาเลือกรูป"
    prepend-icon="mdi-camera"
    label="รูปแนบประกาศ"
    @change="select"
    :loading="loading"
  ></v-file-input>
              </v-col>
          </v-row>
             </v-form>
      </v-card-text>
      <v-card-actions>
     <v-row class="pr-3 pl-3">
              <v-col cols="6" class="pa-2"><v-btn block outlined color="primary" large @click="$emit('onClose')"><span class="text-title-2">ยกเลิก</span></v-btn></v-col>
              <v-col cols="6" class="pa-2"><v-btn v-if="!edit" block color="primary" large @click="onSubmit()"><span class="text-title-2">บันทึก</span></v-btn>
              <v-btn v-else block color="primary" large @click="onUpdate()"><span class="text-title-2">บันทึก</span></v-btn>
              </v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import TextArea from '@/components/common/TextArea'
import { postNewsFile, getNewsFile } from '@/api/'
import Reduce from 'image-blob-reduce'
import Select from '@/components/common/Select'
// import SwitchButton from '@/components/common/Switch'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    },
    receiverList: {
      type: Array
    },
    newsTypeList: {
      type: Array
    },
    groupList: {
      type: Array
    }
  },
  components: {
    TextField,
    TextArea,
    Select
    // SwitchButton
  },
  data () {
    return {
      valid: false,
      titleRules: [
        v => !!v || 'กรุณาระบุหัวข้อ'
      ],
      bodyRules: [v => !!v || 'กรุณาระบุเนื้อหา'],
      newsTypeRules: [v => !!v || 'กรุณาเลือก'],
      receiverRules: [v => !!v || 'กรุณาเลือก'],
      groupRules: [v => !!v || 'กรุณาเลือก'],
      fileRules: [
        value => !value || value.size < 2000000 || 'รูปต้องมีขนาดเล็กกว่า 2 MB'
      ],
      loading: false,
      imgUrl: null,
      newsType: null,
      receiver: null,
      groupId: null
    }
  },
  mounted () {
    if (this.edit && this.value.bannerName) {
      this.getAnnouncementBanner(this.value.bannerName)
    }
  },
  methods: {
    select (file) {
      const rec = new Reduce()
      rec.toBlob(file, { max: 4000 }).then(blob => {
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          this.loading = true
          this.imgUrl = e.target.result
          const arrBase64 = e.target.result.split(',')
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          const data = {
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            fileType: arrBase64[0].match(/:(.*?);/)[1],
            fileEncode: arrBase64[1]
          }
          postNewsFile(data, message => {
            console.log(message.data)
            if (message.data.code === 1) {
              this.$emit('change', { ...this.value, bannerName: message.data.result.fileName })
            }
            this.loading = false
          }, error => {
            this.loading = false
            console.log(error)
          })
        }
      })
    },
    getAnnouncementBanner (bannerName) {
      if (!bannerName) return
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getNewsFile({ tenantId: tenant.tenantId, siteId: site.siteId, fileName: bannerName }, message => {
        if (message.data.code === 1) {
          this.imgUrl = message.data.result.url
        }
      }, error => {
        console.log(error)
      })
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        // this.$emit('change', { fullname: this.fullname, address: this.address, mobile: this.mobile.replace(/^(\+)|\D/g, '$1'), gender: this.gender, birthdate: this.birthdate, cid: this.cid.replace(/^(\+)|\D/g, '$1') }) // replace(/^(\+)|\D/g, '$1')
        this.$emit('onSubmit')
      }
    },
    onUpdate () {
      if (this.$refs.form.validate()) {
        this.$emit('onUpdate')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
